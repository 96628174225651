




































































































































import Vue from 'vue';
import draggable from 'vuedraggable';
import TabMenu from './TabMenu.vue';
import { mapGetters } from 'vuex';
import { PollType } from '@/api/interfaces.api';

export default Vue.extend({
  name: 'PollForm',
  components: { TabMenu, draggable },
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    pollId: {
      type: String,
      required: false,
      default: '',
    },
    presenterSessionId: {
      type: String,
      required: true,
    },
    selectedStage: {
      type: String,
      required: false,
      default: '',
    },
  },
  mounted() {
    // TODO: Not working on page reload
    if (this.isEditMode && this.poll) {
      this.form = {
        title: this.poll.title,
        choices: this.poll.choices,
        type:
          this.poll.type === 0
            ? PollType.SingleChoice
            : this.poll.type === 1
            ? PollType.MultipleChocie
            : PollType.TextField,
        showTextField:
          this.poll.textFieldPlaceholder &&
          this.poll.textFieldPlaceholder != '',
        textFieldPlaceholder: this.poll.textFieldPlaceholder,
        isActive: this.poll.isActive ?? false,
        initialPosition: this.poll.initialPosition ?? 0,
        position: this.poll.position ?? 0,
        isViewerPieChartVisible: this.poll.isViewerPieChartVisible ?? false,
        showPollStatistics: this.poll.showPollStatistics ?? false,
      };
    }
  },
  data() {
    return {
      form: {
        title: '',
        choices: [] as string[],
        type: PollType.SingleChoice,
        textFieldPlaceholder: '',
        showTextField: false,
        isActive: false,
        initialPosition: 0,
        position: 0,
        isViewerPieChartVisible: false,
        showPollStatistics: false,
      },
      drag: false,
      choiceNumber: 0,
    };
  },
  computed: {
    ...mapGetters({
      shareURL: 'presenter/getShareURL',
      getCurrentPoll: 'presenter/getCurrentPoll',
    }),
    poll(): any {
      if (this.pollId) {
        const [poll] = this.getCurrentPoll(this.pollId);
        return poll;
      }
      return null;
    },
    tabItems(): any {
      return [
        { id: 0, name: 'Singlechoice', isSelected: this.form.type == 0 },
        {
          id: 1,
          name: 'Multiplechoice',
          isSelected: this.form.type == 1,
        },
      ];
    },
    isFormSubmitable(): boolean {
      const choicesState =
        this.form.choices.length > 0 &&
        this.form.choices.every((choice: string) => choice !== '');
      const textFieldState =
        this.form.showTextField && this.form.textFieldPlaceholder != '';
      return (
        this.form.title != '' &&
        (choicesState || textFieldState || (choicesState && textFieldState))
      );
    },
  },
  methods: {
    async submitForm() {
      if (this.isEditMode) {
        await this.$store.dispatch('presenter/updatePoll', {
          form: this.form,
          pollId: this.pollId,
          selectedStage: this.selectedStage,
        });
      } else {
        await this.$store.dispatch('presenter/createPoll', {
          form: this.form,
          selectedStage: this.selectedStage,
        });
      }

      this.onSubmit();
    },

    addChoice() {
      this.form.choices.push('');

      const number = this.choiceNumber;
      const refs = this.$refs as any;

      this.$nextTick(() => refs[`choiceInput${number}`][0].focus());
      this.choiceNumber++;
    },
    addTextField() {
      this.form.showTextField = true;
    },
    removeTextField() {
      this.form.showTextField = false;
      this.form.textFieldPlaceholder = '';
    },
    removeChoice(i: number) {
      this.form.choices.splice(i, 1);
      this.choiceNumber--;
    },
    onTabMenuSelect(i: number) {
      this.form.type = i;
    },
  },
});
