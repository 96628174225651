












































import Vue from 'vue';
import PollForm from '@/components/Presenter/PollForm.vue';
import BackButton from '@/components/Presenter/BackButton.vue';

export default Vue.extend({
  components: { BackButton, PollForm },

  data() {
    return {};
  },

  methods: {
    submit() {
      this.$router.back();
    },
  },
});
